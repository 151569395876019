<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col>
          <h1>{{ title | capitalize }}</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <router-view></router-view>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

import {SendsMessages} from "@/mixins";

export default {
  name: "BasePage",
  mixins: [SendsMessages],
  computed: {
    title() {
      if (this.$route.name.indexOf('LIST') !== -1) {
        return this.$t('LIST_PAGE_TITLE', {catalog: this.catalogs});
      } else if (this.$route.name.indexOf('REGISTER') !== -1) {
        return this.$t('REGISTER_PAGE_TITLE', {catalog: this.catalog});
      } else if (this.$route.name.indexOf('EDIT') !== -1) {
        return this.$t('EDIT_PAGE_TITLE', {catalog: this.catalog});
      } else {
        return this.$t(this.$route.name);
      }
    }
  }
}
</script>

<style scoped>

</style>
